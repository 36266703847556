<template>
  <div class="terminal">
    <div class="banner" ref="banner">
      <img src="@/assets/img/terminal/banner.png" />
    </div>
    <div class="terminalContent">
      <div class="side">
        <div class="tree">
          <div class="first" v-for="(v, k) in sideTreeList">
            <div :class="k == checkNum ? 'title checked' : 'title'" @click="checkedFn(v, k)">
              <img :src="require(`@/assets/img/terminal/${k == checkNum ? 't' : 'noT'}reeNode.svg`)" />
              <p>{{ v.label }}</p>
            </div>
            <ul :class="checkIndex.indexOf(k) != -1 ? 'sub_check' : 'sub_uncheck'" :style="'height:' + (v.children.length * 0.3 + (v.children.length - 1) * 0.2) + 'rem'">
              <li :class="detailNum == i && k == checkNum ? 'detailBg' : ''" v-for="(j, i) in v.children" @click="openDetail(j, i, v, k)">
                {{ j.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="right">
        <div class="details">
          <div class="banner">
            <div class="title">
              <h1>{{ currentSideItem.label }}</h1>
              <p>{{ currentSideItem.desc }}</p>
            </div>
            <img :src="currentSideItem.imgsrc" />
          </div>
          <h2 v-if="currentSideItem.advantageList">核心优势</h2>
          <div class="advantage" v-if="currentSideItem.advantageList">
            <div class="advantageItem" v-for="v in currentSideItem.advantageList">
              <img :src="v.img" />
              <h3>{{ v.title }}</h3>
              <p>{{ v.tip }}</p>
            </div>
          </div>
          <h2>产品参数</h2>
          <div class="tableBox">
            <div class="paramsItem" v-for="item in currentSideItem.prodParams">
              <div class="left">
                <p>{{ item.label }}</p>
              </div>
              <div class="right">
                <p>{{ item.value }}</p>
              </div>
            </div>
            <img src="@/assets/img/terminal/tableBg.png" class="tableBox_bg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { terminalList } from '@/assets/data/terminal'
import side from '@/components/side.vue'
export default {
  name: 'terminal',
  components: {
    side,
  },
  computed: {
    sideTreeList() {
      return terminalList
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === '/terminal') {
        this.checkNum = 0
        this.detailNum = 0
        this.sideTreeList.map((v, vi) => {
          v.children.map((t, ti) => {
            if (t.label === to.query.name) {
              this.checkIndex = [vi]
              this.checkNum = vi
              this.detailNum = ti
            }
          })
        })
        this.currentSideItem = this.sideTreeList[this.checkNum].children[this.detailNum]
      }
    },
  },
  data() {
    return {
      checkIndex: [],
      checkNum: 0,
      detailNum: 0,
      currentSideItem: {},
    }
  },
  methods: {
    checkedFn(v, k) {
      if (this.checkIndex.indexOf(k) !== -1) {
        const idx = this.checkIndex.indexOf(k)
        this.checkIndex.splice(idx, 1)
      } else {
        this.checkIndex.push(k)
      }
    },
    openDetail(j, i, v, k) {
      if (this.detailNum == i && this.checkNum == k) return
      this.checkNum = k
      this.detailNum = i
      this.currentSideItem = this.sideTreeList[this.checkNum].children[this.detailNum]
      this.$router.replace({
        path: '/terminal',
        query: {
          name: j.label,
        },
      })
      this.handleSlide()
    },
    handleSlide() {
      window.scrollTo({
        top: this.$refs.banner.clientHeight,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
  created() {
    const prodName = this.$route.query.name
    this.checkNum = 0
    this.detailNum = 0
    this.checkIndex = [0]
    if (prodName) {
      this.sideTreeList.map((v, vi) => {
        v.children.map((t, ti) => {
          if (t.label === prodName) {
            this.checkIndex = [vi]
            this.checkNum = vi
            this.detailNum = ti
          }
        })
      })
      if (this.checkNum == 0 && this.detailNum == 0) {
        this.$router.push('/terminal')
      }
    } else {
      this.$router.push('/terminal')
    }
    this.currentSideItem = this.sideTreeList[this.checkNum].children[this.detailNum]
    this.$forceUpdate()
  },
  mounted() {},
  beforeDestroy() {},
}
</script>
<style scoped lang="less">
.terminal {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
  .banner {
    width: 100%;
    height: 3.9rem;
    position: relative;
    background: #eceaec;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .terminalContent {
    padding: 0 3.57rem 0.6rem;
    display: flex;
    justify-content: space-between;
    .side {
      position: relative;
      .tree {
        z-index: 2;
        position: sticky;
        top: 1.78rem;
        margin-top: 1.08rem;
        .first {
          font-size: 0.18rem;
          color: #333333;
          margin-bottom: 0.4rem;
          .title {
            display: flex;
            cursor: pointer;
            align-items: center;
            img {
              width: 0.14rem;
              height: 0.14rem;
              margin-right: 0.07rem;
              z-index: 10;
            }
            p {
              word-break: keep-all;
            }
          }
          .checked {
            color: #00a4ea;
          }
          ul {
            padding-left: 0.17rem;
            height: 0;
            opacity: 1;
            z-index: 0;
            li {
              padding-left: 0.13rem;
              font-size: 0.16rem;
              // width: 1.26rem;
              height: 0.3rem;
              line-height: 0.3rem;
              margin-top: 0.2rem;
              cursor: pointer;
              &:hover {
                color: #00a4ea;
              }
            }
            .detailBg {
              background-image: url('../assets/img/terminal/detailBg.svg');
              background-size: cover;
              color: #fff;
              &:hover {
                color: #fff;
              }
            }
          }
          .sub_check {
            opacity: 1;
            height: auto;
            overflow: visible;
            transition: height 0.4s, opacity 0.4s;
          }
          .sub_uncheck {
            opacity: 0;
            height: 0 !important;
            overflow: hidden;
            transition: height 0.4s, opacity 0.4s;
          }
        }
      }
      .line {
        width: 0.04rem;
        height: 2rem;
        background: #c9cdd4;
        border-radius: 0rem 0rem 0rem 0rem;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0.05rem;
        height: 100%;
        z-index: 1;
      }
    }
    .right {
      .details {
        .banner {
          width: 9rem;
          height: 3.86rem;
          background-image: url('../assets/img/terminal/detailBanner.png');
          background-size: cover;
          margin-top: 0.8rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            margin-left: 1rem;
            h1 {
              font-size: 0.32rem;
              color: #333333;
            }
            p {
              font-size: 0.18rem;
              color: #333333;
              margin-top: 0.18rem;
            }
          }
          img {
            width: auto;
            height: 3.2rem;
            margin-right: 1rem;
          }
        }
        h2 {
          margin: 0.5rem 0 0.2rem 0;
          color: #666666;
          font-size: 0.28rem;
        }
        .advantage {
          display: grid;
          //   grid-template-rows: 3.38rem 3.38rem 3.38rem;
          grid-template-columns: 2.87rem 2.87rem 2.87rem;
          grid-gap: 0.3rem 0.18rem;
          .advantageItem {
            height: 3.38rem;
            border-radius: 0.04rem 0.04rem 0.04rem;
            align-items: center;
            border: 0.01rem solid #e5e8ef;
            padding-right: 0.35rem;
            transition: box-shadow 0.4s;
            cursor: pointer;
            &:hover {
              box-shadow: 0.04rem 0.04rem 0.1rem 0.01rem rgba(0, 0, 0, 0.1);
            }
            img {
              width: 0.9rem;
              height: 0.9rem;
              display: block;
              margin: 0.7rem 0 0.3rem 0.35rem;
            }
            h3 {
              font-size: 0.2rem;
              color: #666666;
              margin: 0 0 0.1rem 0.35rem;
            }
            p {
              font-size: 0.16rem;
              color: #666666;
              margin-left: 0.35rem;
            }
          }
        }
        .tableBox {
          width: 9.78rem;
          // height: 9.3rem;
          background: #ffffff;
          border-radius: 0rem 0rem 0rem 0rem;
          opacity: 1;
          border: 0.01rem solid #e5e8ef;
          position: relative;
          .paramsItem {
            width: 100%;
            display: flex;
            &:first-child {
              .left,
              .right {
                padding-top: 0.2rem;
              }
            }
            &:last-of-type {
              .left,
              .right {
                padding-bottom: 0.2rem;
              }
            }
            .left {
              width: 2rem;
              background: #f6f7fb;
              text-align: center;
            }
            .right {
              flex: 1;
              text-align: left;
              padding-left: 0.4rem;
              padding-right: 0.2rem;
              p {
                font-weight: 400;
              }
            }
            p {
              font-size: 0.16rem;
              font-weight: bold;
              color: #666666;
              line-height: 0.5rem;
            }
          }
          .tableBox_bg {
            width: 7.78rem;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 0;
          }
          .tableBox_left {
            width: 2rem;
            background: #f6f7fb;
            // height: 9.1rem;
            padding-top: 0.2rem;
            p {
              text-align: center;
              font-size: 0.16rem;
              font-weight: bold;
              color: #333333;
              line-height: 0.5rem;
            }
          }
          .table_right {
            // height: 9.1rem;
            padding-top: 0.2rem;
            padding-left: 0.39rem;
            flex: 1;
            background-image: url('../assets/img/terminal/tableBg.png');
            background-repeat: no-repeat;
            background-size: 6.74rem 3.28rem;
            background-position: right bottom;
            padding-bottom: 0.2rem;
            p {
              font-size: 0.16rem;
              font-weight: 400;
              color: #333333;
              line-height: 0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>
